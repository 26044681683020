



import {Component, Vue, Prop} from 'vue-property-decorator';
import Point from '../../../models/Point';

@Component
export default class HexagonComponent extends Vue {

  @Prop()
  private item!: Point;
}

