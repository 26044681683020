



import {Component, Prop, Vue} from 'vue-property-decorator';
import Point from '../../../models/Point';

@Component
export default class CircleComponent extends Vue {

  @Prop()
  private item!: Point;
}

