import httpService from '../services/http';
import Project from '../models/Project';


export default class ProjectService {

    public static async getEmbed(hash: string): Promise<Project | null> {
        return httpService.get('embed/' + hash)
            .then((response) => response.data)
            .catch((error) => null)

    }
}
