









import {Component, Vue} from 'vue-property-decorator';
import KonvaEmbed from './KonvaEmbed.vue';
import ProjectStore from '../../store/projectStore';


@Component({
  components: {
    KonvaEmbed,
  },
})


export default class Embed extends Vue {

  public mounted() {
    ProjectStore.getEmbed(this.$route.params.hash);
  }

};
