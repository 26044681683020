export default class Table {
  public headers: Array<{ text: string; align: string; sortable: boolean; value: string; }> = [{
    text: 'Layer column',
    align: 'start',
    sortable: false,
    value: 'layer',
  }, {
    text: 'Value column',
    align: '',
    sortable: false,
    value: 'value',
  }];
  public data: Array<{}> = [{
    layer: 'Layer name',
    value: 123,
  }, {
    layer: 'Layer name2',
    value: 123,
  }];

  constructor(args: Partial<Table>) {
    Object.assign(this, args)
  }
}

