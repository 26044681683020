import Tab from '../models/Tab';
import User from './User';
import Table from './Table';
import DrawParametersInterface from '../interfaces/DrawParameters';

export default class Project {
    public id: number = NaN;
    public hash: string = '';
    public user: User = new User();
    public isPublic: boolean = false;
    public name: string = '';
    public drawParameters: DrawParametersInterface = {
        mismatch: false, pointSize: 20, offsetX: 2, offsetY: 2, shape: 'square', rotation: 0,
        stageSize: {width: 1100, height: 500},
    }
    public image: string = '';
    public imageDisplay: boolean = true;
    public tooltipDisplay: boolean = true;
    public imageCoordinates: { x: number, y: number, width: number, height: number, rotation: number } = {
        x: 0,
        y: 0,
        width: 300,
        height: 300,
        rotation: 0,
    };
    public tabs: Tab[] = [];
    public table: Table = new Table({});
    public sourceLabel: string = '';
    public sourceName: string = '';
    public sourceUrl: string = '';

    constructor(args: Partial<Project>) {
        Object.assign(this, args)
    }
}

