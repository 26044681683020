






import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class App extends Vue {
  private created() {
    // AuthStore.init();
    this.$vuetify.theme.dark = false;
    this.$vuetify.theme.themes.dark.primary = '#6C7AE6';
    this.$vuetify.theme.themes.light.primary = '#6349c5';
    this.$vuetify.theme.themes.light.primary = '#000';
  }
};
