import {getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import store from '.';
import Konva from 'konva';
import ProjectStore from '../store/projectStore';


@Module({
  dynamic: true,
  namespaced: true,
  name: 'projectUtilitiesStore',
  store,
})

class ProjectUtilitiesStore extends VuexModule {
  public imageEdit: boolean = false;
  public pointsGridMovement: boolean = false;
  public loading: boolean = true;
  public image: HTMLImageElement = new window.Image();
  public activeLayerIndex: number = NaN;
  public activeTabIndex: number = NaN;
  public tabRedrawUtils: boolean = false;
  public mouseClick: boolean = false;
  public permanentAutodrawing: boolean = false;
  public eraser: boolean = false;
  public stage: Konva.Stage | null = null;
  public patternLayer: Konva.Layer = new Konva.Layer({id: 'patternLayer'});
  public patternPointFill: string = '#ADAAC1';
  public imageLayer: Konva.Layer = new Konva.Layer({id: 'imageLayer'});
  public tooltipLayer: Konva.Layer = new Konva.Layer({id: 'tooltipLayer'});
  public tooltip: Konva.Text = new Konva.Text({
    x: 100,
    y: 100,
    visible: false,
    fontFamily: 'Calibri',
    fontSize: 24,
    fill: 'black',
    textFill: '',
    alpha: 0.75,
    padding: 0,
    text: '',
    stroke: '',
  });
  public fillArea: boolean = false;
  public imageOpacity: number = 70;


  @Mutation
  public setImageOpacity(payload: number): void {
    this.imageOpacity = payload;
  }

  @Mutation
  public setFillArea(payload: boolean): void {
    this.fillArea = payload;
  }

  @Mutation
  public setTabRedrawUtils(): void {
    this.tabRedrawUtils = !this.tabRedrawUtils;
  }

  @Mutation
  public setStageSize(stageSize: { width: number, height: number }): void {
    this.stage?.height(stageSize.height);
    this.stage?.width(stageSize.width);
  }

  @Mutation
  public setStage(stage: Konva.Stage): void {
    this.stage = stage;
  }

  @Mutation
  public setMouseClick(state: boolean): void {
    this.mouseClick = state;
  }

  @Mutation
  public setActiveLayer(id: number): void {
    if (isNaN(id)) {
      this.activeLayerIndex = id;
    } else {
      this.activeLayerIndex =
        ProjectStore.project.tabs[this.activeTabIndex].layers.findIndex((layer) => layer.id === id);
    }
  }

  @Mutation
  public setActiveTab(id: number): void {
    if (isNaN(id)) {
      this.activeTabIndex = id;
    } else {
      this.activeTabIndex = ProjectStore.project.tabs.findIndex((tab) => tab.id === id);
    }
  }

  @Mutation
  public setImageEdit(bool: boolean): void {
    this.imageEdit = bool;
  }


  @Mutation
  public setPointsGridMovement(bool: boolean): void {
    this.pointsGridMovement = bool;
  }

  @Mutation
  public setLoading(loading: boolean): void {
    this.loading = loading;
  }

  @Mutation
  public setImage(image: HTMLImageElement): void {
    this.image = image;
  }

  @Mutation
  public setPermanentAutodrawing(payload: boolean): void {
    this.permanentAutodrawing = payload;
  }

  @Mutation
  public setEraser(payload: boolean): void {
    this.eraser = payload;
  }

  @Mutation
  public setImageLayer(layer: Konva.Layer): void {
    this.imageLayer = layer;
  }

  @Mutation
  public setPatternLayer(layer: Konva.Layer): void {
    this.patternLayer = layer;
  }

  @Mutation
  public setTooltipLayer(layer: Konva.Layer): void {
    this.tooltipLayer = layer;
  }

  @Mutation
  public clearStore(): void {
    this.imageEdit = false;
    this.pointsGridMovement = false;
    this.loading = true;
    this.image = new window.Image();
    this.imageOpacity = 70;
    this.activeLayerIndex = NaN;
    this.activeTabIndex = NaN;
    this.mouseClick = false;
    this.permanentAutodrawing = false;
    this.patternLayer.destroyChildren();
    this.imageLayer.destroyChildren();
    this.tooltipLayer.destroyChildren();
  }
}

export default getModule(ProjectUtilitiesStore);
