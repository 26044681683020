import {Action, getModule, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import store from '.';
import Project from '../models/Project';
import ProjectService from '../services/project';
import ProjectUtilitiesStore from './projectUtilitiesStore'


@Module({
    dynamic: true,
    namespaced: true,
    name: 'projectStore',
    store,
})

class ProjectStore extends VuexModule {
    public project: Project = new Project({});

    @Action
    public async getEmbed(hash: string): Promise<any> {

        ProjectUtilitiesStore.setLoading(true);
        try {
            ProjectUtilitiesStore.clearStore();
            const data = await ProjectService.getEmbed(hash);
            if (data) {
                this.setProject(data);

            }
        } catch (e) {
            console.error(e);
        }
        ProjectUtilitiesStore.setLoading(false);
    }



    // @Mutation
    // private clearStore() {
    //     this.project = new Project({});
    // }

    @Mutation
    private setProject(project: Project): void {
        this.project = new Project(project);
    }

}

export default getModule(ProjectStore);
