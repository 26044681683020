var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('div',[_vm._v("LOADING...")]):_c('div',[_c('v-card',{staticClass:"relative",attrs:{"flat":"","id":"stage-parent"}},[_c('v-tabs',{staticClass:"itab",attrs:{"grow":""},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.project.tabs),function(tab){return _c('v-tab',{key:tab.id},[_vm._v(" "+_vm._s(tab.name)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.project.tabs),function(tab){return _c('v-tab-item',{key:tab.id},[_c('div',{staticClass:"stage-parent"},[_c('v-card',{staticClass:"stage-container",attrs:{"flat":""}},[_c('v-stage',{ref:"stage",refInFor:true,style:({ 'background-image': 'url(' + tab.image + ')'}),attrs:{"config":{width: _vm.widthw, height: _vm.heighth, scale: {x: _vm.scalex, y: _vm.scaley}}}},[_c('v-layer',{ref:"pointLayer",refInFor:true,on:{"mousemove":_vm.addTooltip,"mouseout":_vm.removeTooltip}},[_vm._l((tab.layers),function(layer){return _c('v-group',{key:layer.id,ref:"group",refInFor:true,attrs:{"visible":layer.visible,"item":layer}},_vm._l((layer.data),function(shape){return _c(_vm.component,{key:shape.id,tag:"component",attrs:{"item":shape}})}),1)}),_vm._l((tab.texts),function(textr){return _c('v-text',{key:textr.id,attrs:{"visible":_vm.text.visible,"item":textr,"config":{
                  text: textr.name,
                  y: textr.pozY,
                  x: textr.pozX,
                  fill: textr.color,
                  fontSize: textr.sizet,
                  fontFamily: 'Calibri',
                  visible: textr.visible}}})}),_vm._l((tab.arrows),function(arrow){return _c('v-arrow',{key:arrow.id,attrs:{"visible":_vm.text.visible,"item":arrow,"config":{
                  y: arrow.pozy,
                  x: arrow.pozx,
                  fill: arrow.color,
                  points: [arrow.pozx1, arrow.pozy1, arrow.pozx2, arrow.pozy2, arrow.pozx3, arrow.pozy3],
                  visible: arrow.visible,
                  stroke: arrow.color,
                  tension: 0.5 }}})})],2),_c('v-layer',{ref:"borderLayer",refInFor:true},_vm._l((tab.borders.points),function(point){return _c('v-rect',{key:point.id,attrs:{"config":point}})}),1)],1)],1)],1),_c('v-row',{staticClass:"justify-center legends-embed",attrs:{"align":"center"}},_vm._l((tab.legendRangesPerColor.legend),function(legend,index){return _c('span',{attrs:{"item":legend}},[_c('v-icon',{attrs:{"aria-hidden":"false","x-small":"","color":legend.color}},[_vm._v("mdi-circle")]),_vm._v(" "+_vm._s(legend.description)+" ")],1)}),0)],1)}),1),(this.tooltiptext)?_c('div',{style:({
          opacity:  this.tooltipcss.opacity,
          visibility: this.tooltipcss.visibility,
          top: this.tooltipcss.top + 'px',
          left: this.tooltipcss.left + 'px',
          }),attrs:{"id":"tooltipdiv"}},[_vm._v(" "+_vm._s(this.tooltiptext)+" ")]):_vm._e(),_c('div',{staticClass:"footer_biqmap text-right",model:{value:(_vm.projectSource),callback:function ($$v) {_vm.projectSource=$$v},expression:"projectSource"}},[(_vm.textUrl)?_c('span',[_c('a',{attrs:{"href":_vm.textLink}},[_vm._v(_vm._s(_vm.textValue)+" ")])]):_c('span',[_vm._v(_vm._s(_vm.textValue)+" ")]),_c('a',{staticClass:"source_ico logosbiq biqmap",attrs:{"href":"http://biqmap.com","target":"_blank"}}),_c('a',{staticClass:"source_ico logosbiq biqdata",attrs:{"href":"http://biqdata.wyborcza.pl","target":"_blank"}}),_c('a',{staticClass:"source_ico logosbiq wyborcza",attrs:{"href":"http://wyborcza.pl","target":"_blank"}})])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }