import axios from 'axios';


let urlApi: string | undefined = '';
if (window.location.hostname.includes('test')) {
    urlApi = process.env.VUE_APP_API_TEST_URL;
} else {
    urlApi = process.env.VUE_APP_API_URL;
}

const httpService = axios.create({
    baseURL: urlApi,
    headers: {
        'Content-Type': 'application/json',
    },
});

export default httpService;
