











































































































import {Component, Vue} from 'vue-property-decorator';
import VueKonva from 'vue-konva';
import ProjectStore from '@/store/projectStore';
import ProjectUtilitiesStore from '../../store/projectUtilitiesStore';
import CircleComponent from './shapes/Circle.vue';
import SquareComponent from './shapes/Square.vue';
import HexagonComponent from './shapes/Hexagon.vue';
import Project from '../../models/Project';
import Konva from 'konva';
import {KonvaEventObject} from 'konva/types/Node';

const widthall = window.innerWidth;
const heightall = window.innerHeight;


Vue.use(VueKonva);

@Component({
  components: {
    CircleComponent,
    SquareComponent,
    HexagonComponent,
  },
})
export default class KonvaEmbed extends Vue {

  private toolpointSize: number = 0;
  private tooltabs: number = 0;
  private tooltiptext: string = '';
  public widthw: number = 1100;
  public heighth: number = 500;
  public scalex: number = 1;
  public scaley: number = 1;
  public widthstatic: number = 1100;
  public heightstatic: number = 500;

  private tooltipcss: {
    left: number,
    top: number,
    opacity: number,
    visibility: string,
  } = {
    left: 0,
    top: 0,
    opacity: 0,
    visibility: 'hidden',
  };


  private text: {
    x: number,
    y: number,
    visible: boolean,
    fontFamily: string,
    fontSize: number,
    fill: string,
    text: string,
  } = {
    x: 100,
    y: 100,
    visible: true,
    fontFamily: 'Calibri',
    fontSize: 24,
    fill: 'black',
    text: 'default',
  };

  private tabs: null = null;
  private textValue: string = '';
  private textUrl: boolean = false;
  private textLink: string = '';
  private container: any = '';

  private get component(): typeof CircleComponent | typeof SquareComponent | typeof HexagonComponent | undefined {
    const shape: string = this.project.drawParameters.shape;
    if (shape === 'square') {
      return SquareComponent;
    } else if (shape === 'circle') {
      return CircleComponent;
    } else if (shape === 'hexagon' || shape === 'hexagon-rotated90') {
      return HexagonComponent;
    } else {
      return SquareComponent;
    }
  }

  private mounted() {

    this.toolpointSize = this.project.drawParameters.pointSize;
    this.fitStageIntoParentContainer();
  }

  private created() {
    window.addEventListener('resize', this.fitStageIntoParentContainer);
  }

  private get project(): Project {
    return ProjectStore.project;
  }

  private get loading(): boolean {
    return ProjectUtilitiesStore.loading;
  }

  private get projectSource() {
    this.textLink = this.project.sourceUrl;
    if (this.textLink !== '') {
      this.textUrl = true;
    }

    if (this.project.sourceLabel !== null && this.project.sourceName !== null) {
      this.textValue = this.project.sourceLabel + ' ' + this.project.sourceName;
    } else {
      this.textValue = '';
    }

    return true;
  }

  private async addTooltip(event: KonvaEventObject<MouseEvent>): Promise<void> {
    const currentPoint: Konva.Shape = event.target as Konva.Shape;
    this.tooltipcss.left = currentPoint.attrs.x + this.toolpointSize;
    this.tooltipcss.top = currentPoint.attrs.y  + this.toolpointSize + this.tooltabs;
    this.tooltipcss.visibility = 'visible';
    this.tooltipcss.opacity = 1;
    if (currentPoint.parent?.attrs.item !== undefined) {
      if ((currentPoint.parent?.attrs.item?.tooltip !== null)) {
       this.tooltiptext = currentPoint.parent?.attrs.item.tooltip.replace(
        '{value}', currentPoint.parent?.attrs.item.tooltipValue);
      } else {
       this.tooltiptext = '';
      }
    }
  }

  private removeTooltip(event: KonvaEventObject<MouseEvent>): void {
    this.tooltipcss.visibility = 'hidden';
    this.tooltipcss.opacity = 0;
    this.tooltiptext = '';

  }


  public fitStageIntoParentContainer() {

    this.widthstatic = this.project.drawParameters.stageSize.width;
    this.heightstatic = this.project.drawParameters.stageSize.height;

    const scale = widthall / this.project.drawParameters.stageSize.width;
    // console.log('widthall= ', widthall);
    // console.log('hightall= ', heightall);
    // console.log('scale= ', scale);
    if (Number(widthall) < this.project.drawParameters.stageSize.width) {
     this.widthw = Number(scale * this.widthstatic);
     this.heighth = Number(scale * this.heightstatic) + 30;
     this.scalex = Number(scale);
     this.scaley = Number(scale);
    } else {
      this.widthw = this.project.drawParameters.stageSize.width;
      this.heighth = this.project.drawParameters.stageSize.height + 30;
      this.scalex = 1;
      this.scaley = 1;
    }

  }

}

